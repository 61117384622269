<template>
    <div id="pendings" class="wh-container ">
      <div class="header no-print">
        <p class="title">Gerenciar pendências</p>
      </div>
  
      <Tab
        :index="currentTabIndex"
        ref="warehouse_tabs"
        :keys="tabs"
        @onChange="onChangeTab"
      >
        <router-view />
      </Tab>
      </div>    
</template>
<script>

export default {
  name: 'Pending',
  components: {
    Tab: () => import('@/components/Tab.vue')
  },
  data() {
    return {
      currentTabIndex: 0,
      tabs: [
        { key: 'dashboard', label: 'Pendências', route: 'dashboard' },
        { key: 'history', label: 'Histórico', route: 'history' },
      ]
    }
  },
  mounted() {
    const path = this.$route.path;
    const tab = this.tabs.find(tab => path.includes(tab.route));
    this.currentTabIndex = this.tabs.indexOf(tab);
    
  },
  methods: {
    onChangeTab(index) {
      this.currentTabIndex = index;

      const tab = this.tabs[index];
      this.$router.push(`/financeiro/pendings/${tab.route}`);
    }
  }
}

</script>
<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    color: var(--blue-500);
  }
}
</style>

